<template>
  <base-layout>
    <template v-slot:title>
      <div class="text-lg">
        
        ログイン
      </div>
    </template>
    <div
      class="flex items-center h-full justify-center"
    >
      <form
        @submit.prevent="onLogin"
        class="px-10 pt-10"
      >
        <!-- <text-field
          type="text"
          label="企業ID"
          class="mb-5"
        ></text-field>
         -->
        <text-field
          type="text"
          label="ユーザID"
          class="mb-5"
          v-model="formData.email"
        ></text-field>
        
        <text-field
          type="password"
          label="パスワード"
          class="mb-10"
          v-model="formData.password"
        ></text-field>
        <div
          class="mb-3"
        >
          <button
            type="submit"
            class="w-full success "
          >
            ログイン
          </button>
        </div>
        <div class="mb-8">
          <p>パスワードを忘れた方は<a href="/password-reset" style="text-decoration: underline">こちら</a></p>
        </div>
        <div>
          <button
            type="button"
            ref="authorizeButton"
            class="w-full bordered mb-3 rounded-full"
          >
            Sign in with Google  
          </button>
        </div>
        
        
        
      </form>
    </div>
  </base-layout>
</template>
<script >
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ILogin } from '@/types/Interfaces';
// import GSignInButton from 'vue-google-signin-button'

import useAuth from '@/types/Auth';
import useFlashMessage from '@/types/FlashMessage';
import { result } from 'lodash';
import { client } from '@/types/Axios';

export default defineComponent({
  components: {
    // GSignInButton
  },
  setup() {
    const router = useRouter();

    const {
      setMessage  
    } = useFlashMessage();

    const {
      createToken,
      removeToken,
      storeToken,
      getMe,
      storeProfile,
      getProfileFromLocalStorage,
      removeProfile,
      getGoogleOauthClientId,
      signInWithGoogle

    } = useAuth();
    
    const formData = ref({
      email: '',
      password: '' 
    });
    
    const form = ref(null);

    // const loginError = ref<string>('');
    
    // const setLoginError = (message: string) => {
    //   // loginError.value = message;
    //   // window.setTimeout(() => {
    //   //   loginError.value = '';
    //   // }, 5000);  
    //   setMessage(message)
    // };
    
    // 1. Load the JavaScript client library.
    function makeApiCall() {
        gapi.client.people.people.get({
          'resourceName': 'people/me',
          'personFields': 'emailAddresses',
          // 'requestMask.includeField': 'person.names'
        }).then(function(resp) {
          const p = document.createElement('p');
          // const name = resp.result.names[0].givenName;
          console.log(resp)
          // p.appendChild(document.createTextNode('Hello, '+name+'!'));
          // document.getElementById('content').appendChild(p);
        }).catch(err => {
          console.log(err)
        });
      }
    const updateSigninStatus = (isSignedIn) => {
      if (isSignedIn) {
        // authorizeButton.style.display = 'none';
        // signoutButton.style.display = 'block';
        makeApiCall();
        alert('sign in')
      } else {
        // authorizeButton.style.display = 'block';
        // signoutButton.style.display = 'none';
      }
    }

    const onSignInWithGoogle = async (event) => {
      const authInstance = gapi.auth2.getAuthInstance();
      
      const googleUser = await authInstance.signIn();// .then(googleUser => {
      const basicInfo = googleUser.getBasicProfile()
      console.log(basicInfo)
      const email = basicInfo.getEmail()
      const data = await signInWithGoogle(email)
      console.log(data)
      storeToken(data);
      const profileData = await getMe();
      storeProfile(profileData);
      router.push({ name: 'ProposalsList' }) ;
      
      // console.log(authInstance.isSignedIn.get())
      // if (authInstance.isSignedIn.get()) {
      //   const googleUser = authInstance.currentUser.get()
        
      // }
      // const ret = authInstance.signIn(); //isSignedIn.listen(updateSigninStatus);
      // const user = authInstance.currentUser.get()
      
      
      // console.log(user.getAuthResponse())
      // const idToken = authInstance.currentUser.get().getAuthResponse().id_token
      // console.log(idToken)
      // console.log(typeof ret)
            
    }

    // const handleSignoutClick = (event) => {
    //   gapi.auth2.getAuthInstance().signOut();
    // }
    const authorizeButton = ref(null);
    
    const loadGapi = (client_id) => {
      const script = document.createElement('script')
      script.onload = () => {
        console.log('loaded')
        const startGoogleOauth  = async () => {
          /* eslint no-undef: 0 */
          await gapi.client.init({
            clientId: client_id, // '927605646222-f8v8akb58oittjvo9oukvrpinqjdh9fm.apps.googleusercontent.com',
            scope: 'profile',
            prompt: 'select_account'
          }); //.then(() => {

          const authInstance = gapi.auth2.getAuthInstance();
          
      
          if (authInstance.isSignedIn.get()) {
            console.log('you are already signed in')
            console.log(authInstance.currentUser.get())
          } else {
            console.log('not signed in yet')
          }

          authorizeButton.value.onclick = onSignInWithGoogle;
            
            // return gapi.client.request({
            //   'path': 'https://people.googleapis.com/v1/people/me?requestMask.includeField=person.names',
              // }
        };
        gapi.load('client:auth2', startGoogleOauth);
    
      }
      script.src = "https://apis.google.com/js/api.js";
      script.async = true;
      document.head.appendChild(script)
      
    }
    onMounted(() => {
      removeToken();
      removeProfile();
      getGoogleOauthClientId().then(client_id => {
        loadGapi(client_id);
      })
    });

    const loading = ref(false);

    const onLogin = async (event) => {
      // validate
      
      try {
        loading.value = true;
        const tokenData = await createToken(formData.value);
        
        storeToken(tokenData);
        const profileData = await getMe();
        storeProfile(profileData)
        
        router.push({ name: 'ProposalsList' }) ;
      } catch (err) {
        console.log(err)
        if (err.response) {
          const { status, data } = err.response;
          
          if (status >= 400 && status < 500) {
            // alert(JSON.stringify(data))
            // setLoginError('ログインに失敗しました。')
            setMessage({ type: 'error', message: 'ログインに失敗しました' })
          } else {
            setMessage({ type: 'error', message: JSON.stringify(data) })
          
          }
          // formData.value = {
          //   email: '',
          //   password: ''
          // }
        } else {
          console.error('network error')
          // network error or timeout
          // setLoginError(JSON.stringify(err))
        }
        
      }
      loading.value = false;
      
    };

    const googleOauthClientId = ref('927605646222-f8v8akb58oittjvo9oukvrpinqjdh9fm.apps.googleusercontent.com');
    
    return {
      loading,
      form,
      formData,
      // loginError,
      // formValid,
      onLogin,
      googleOauthClientId,
      authorizeButton,
      loadGapi
    };
  }
})
</script>


